import styled from '@emotion/styled';
import { ContainerRow } from '@src/components/styled';
import { BodyLargeSemiBold } from '@src/components/styled/Typography';
const ListHeader = styled(ContainerRow)(props => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: props.theme.spacing_sizes.l,
}));
const ListTitle = styled(BodyLargeSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })((props) => ({
    lineHeight: '24px',
    fontWeight: 600,
    opacity: props.disabled ? 0.5 : 1,
}));
export { ListHeader, ListTitle, };
